import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import YouTube from '../components/YouTube';
import Action from '../components/Action';
import LittleImg from '../components/LittleImg';
import Video from '../components/Video';
export const _frontmatter = {
  "title": "Board Gaming in Figma",
  "date": "2021-03-08",
  "promo": "grids",
  "description": "Using Figma and JavaScript to play",
  "color": "#ba5f25"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`My friends and I are obsessed with a board game called `}<a parentName="p" {...{
        "href": "https://boardgamegeek.com/boardgame/36235/duke"
      }}>{`The Duke`}</a>{`. We take any opportunity to get together for this battle of wits. There's nothing sweeter than that smug feeling of outsmarting your buddy with a cleverly-executed strategy.`}</p>
    <p>{`The pandemic has made it hard to always get together. But my friend `}<a parentName="p" {...{
        "href": "https://twitter.com/AC_Design"
      }}>{`Adam Christiansen`}</a>{` was determined to not let a meager world-ending plague interfere with our shared passion! He put his incredible design skills to work and re-created The Duke in the popular design tool `}<a parentName="p" {...{
        "href": "https://www.figma.com/"
      }}>{`Figma`}</a>{`—the same tool I used to create my `}<a parentName="p" {...{
        "href": "https://mastery.games/post/mastery-games-platform/#New-Logo"
      }}>{`mastery games logo`}</a>{`. Figma is especially good for board gaming with its excellent multi-player functionality.`}</p>
    <h2>{`Design`}</h2>
    <p>{`Hi I’m Adam and I’ll explain how I got started designing the Duke board game in Figma. The Duke is like Chess in a lot of ways. Two players face off on opposite sides of the board, trying to capture a key piece: the Duke.`}</p>
    <LittleImg alt="The Duke board game in Figma" src="/img/duke1.jpg" mdxType="LittleImg" />
 
    <h3>{`Mirroring`}</h3>
    <p>{`The first questions I had to answer to make sure this game didn’t feel like some shanty A piece of garbage were:`}</p>
    <ul>
      <li parentName="ul">{`How do you make a game where players face `}<em parentName="li">{`opposite`}</em>{` directions in a 1-directional interface like Figma?`}</li>
      <li parentName="ul">{`How do you make players' actions show up to their opponent, without getting in their way?`}</li>
    </ul>
    <p>{`The answers to both ended up being a bit obvious but cool. I made two pages, one for each player. I then created a master Board component for each player. Then I put an instance of it on the opponent’s page, rotated at 180º. I also locked that instance so that it showed up but was not interactive. With that setup White can take an action on their Board/page and the locked/rotated instance on Black’s page will reflect that action.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/figma-mirroring.jpg",
        "alt": "mirrored Figma pages"
      }}></img></p>
    <p>{`For example here is what the White player sees:`}</p>
    <LittleImg alt="The Duke board game in Figma" src="/img/duke2.jpg" mdxType="LittleImg" />
    <p>{`And here's the same game from the perspective of the Black player:`}</p>
    <LittleImg alt="The Duke board game in Figma" src="/img/duke3.jpg" mdxType="LittleImg" />
    <p>{`This mirroring trick makes it feel like your opponent is sitting right across from you!`}</p>
    <h3>{`Gameplay`}</h3>
    <p>{`The Duke is different from Chess in that you only start with three pieces. On your turn you can either move an existing piece, or draw a new piece randomly from your bag. Each piece has specific available movement options printed on the front. However, after you move you have to flip the moved piece revealing totally different movement mechanics. We needed a way to quickly switch between sides, as well as obscure the piece before it’s drawn. Figma’s `}<a parentName="p" {...{
        "href": "https://www.figma.com/best-practices/creating-and-organizing-variants/"
      }}>{`variants`}</a>{` were perfect for the job. I created three variants for each piece: `}<em parentName="p">{`hidden`}</em>{`, `}<em parentName="p">{`front`}</em>{` and `}<em parentName="p">{`back`}</em>{`.`}</p>
    <LittleImg alt="Wizard with Figma variants" src="/img/duke-wizard.jpg" mdxType="LittleImg" />
    <p>{`Now to draw a new piece you simply selected a `}<em parentName="p">{`hidden`}</em>{` piece in your bag and switched it to the `}<em parentName="p">{`front`}</em>{` variant. After moving a piece you toggled the `}<em parentName="p">{`front`}</em>{` or `}<em parentName="p">{`back`}</em>{` variant.`}</p>
    <p>{`From here we got to playing! And it was honestly really quite good. I however am blessed to be a designer with three best friends who are killer developers (`}<a parentName="p" {...{
        "href": "https://twitter.com/iammerrick"
      }}>{`Merrick Christensen`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitter.com/geddski"
      }}>{`Dave Geddes`}</a>{` and `}<a parentName="p" {...{
        "href": "https://twitter.com/Hossman333"
      }}>{`Joshy Robertson`}</a>{`). So naturally as we played together in Figma they saw the potential to take things to the next level.`}</p>
    <p>{`I’ll hand off to Dave to explain how he took the Duke to swanky town.`}</p>
    <h2>{`Code`}</h2>
    <p>{`There were several things we had to do by hand that just felt dinky. Every time you moved or captured an opponent's piece you had to fiddle around with Figma pages and layers. Ewwww! Shuffling the bag manually was especially painful.`}</p>
    <p>{`While thinking about ways to improve the Figma board gaming experience, we remembered that Figma now supports custom `}<a parentName="p" {...{
        "href": "https://help.figma.com/hc/en-us/articles/360039958874-Make-plugins-for-the-Figma-Community"
      }}>{`JavaScript plugins`}</a>{` including `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/"
      }}>{`TypeScript`}</a>{`.`}</p>
    <p>{`You can even write the UI for your plugin in modern HTML/CSS. This makes `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`knowing CSS Grid`}</a>{` even `}<em parentName="p">{`more`}</em>{` useful if you can believe it. Figma also comes with the `}<a parentName="p" {...{
        "href": "https://help.figma.com/hc/en-us/articles/360041949073-Use-Chrome-Developer-Tools"
      }}>{`Chrome devtools`}</a>{` so you can debug your scripts and inspect your layouts.`}</p>
    <LittleImg width="600px" alt="CSS Grid in Figma" src="/img/figma-grid.jpg" mdxType="LittleImg" />
    <h3>{`Game setup`}</h3>
    <p>{`In between games you had to do these steps manually:`}</p>
    <ul>
      <li parentName="ul">{`drag all the pieces back to the bag`}</li>
      <li parentName="ul">{`flip them to the `}<em parentName="li">{`hidden`}</em>{` variant`}</li>
      <li parentName="ul">{`shuffle them around manually then align them again`}</li>
      <li parentName="ul">{`get your three starting pieces ready`}</li>
    </ul>
    <p>{`Why do things like this yourself when code can do it for you?!`}</p>
    <p>{`To automate the setup I assigned each piece a unique `}<inlineCode parentName="p">{`id`}</inlineCode>{`—similar to an HTML `}<inlineCode parentName="p">{`id`}</inlineCode>{`—so the pieces could be found and controlled with the Figma `}<a parentName="p" {...{
        "href": "https://www.figma.com/plugin-docs/api/api-overview/"
      }}>{`plugin API`}</a>{`. I tried querying the pieces by layer name at first, but it was `}<em parentName="p">{`waaaaay`}</em>{` too slow in a document like this with lots of layers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const getInstance = (id) => {
  return figma.getNodeById(id);
}
`}</code></pre>
    <p>{`Once your code has a reference to a piece you can move it wherever you want:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const movePiece = (piece, x, y) => {
  piece.x = x;
  piece.y = y;
}
`}</code></pre>
    <p>{`Or control which variant to show:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`piece.mainComponent = WhiteDragoonBack;
`}</code></pre>
    <p>{`Now that I knew how to control the game pieces with code, writing a game setup script was a piece of cake. The game reset script makes pieces go back in the bag, the bag gets a proper `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle"
      }}>{`Fisher-Yates shuffle`}</a>{`, and everything's ready for the next game!`}</p>
    <Video compact="true" src="/video/figmaduke-reset.mp4" mdxType="Video" />
    <h3>{`Kill him!`}</h3>
    <p>{`The Duke is all about moving your pieces around and capturing your opponent's. These were manual processes with several steps. So I made a `}<em parentName="p">{`kill mode`}</em>{`, which generates a new red Figma layer over each enemy piece. Then you can select which piece you want to capture, and the plugin code takes care of it!`}</p>
    <Video compact="true" src="/video/figmaduke-kill.mp4" mdxType="Video" />
    <h2>{`Superpowers`}</h2>
    <p>{`Board gaming is the best and has really helped get me through this rough pandemic. I'll share this Figma document and plugin as soon as I get permission from The Duke publisher. `}</p>
    <p>{`Figma is an `}<em parentName="p">{`incredible`}</em>{` design and collaboration tool—perfect for playing remotely with friends or prototyping your own board games.`}</p>
    <p>{`Knowing how to code is a superpower. It feels like wielding powerful magic, and breathes life into all your other hobbies and passions. When I was learning JavaScript I had no idea it would someday be the thing that enabled my friends and I to spend time together. There's never been a better time to add coding whatever else you love doing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      